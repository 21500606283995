import * as React from "react";
import Layout from "../components/layout";

const IntegrationsPage = () => {
  return (
    <Layout pageTitle="Integrations">
      <p>Integrations</p>
    </Layout>
  );
};

export default IntegrationsPage;
